"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.createReactContext = void 0;
var React = require("react");
function createReactContext(alminContext) {
    var initialState = alminContext.getState();
    var StateContext = React.createContext(initialState);
    // Provider
    var Provider = /** @class */ (function (_super) {
        __extends(Provider, _super);
        function Provider(props) {
            var _this = _super.call(this, props) || this;
            _this.onChange = function () {
                _this.setState(alminContext.getState());
            };
            // If <Provider initialState={state} />, prefer to use the props value
            // This props.initialState is for testing
            _this.state = props.initialState || initialState;
            _this.releaseHandler = alminContext.onChange(_this.onChange);
            return _this;
        }
        Provider.prototype.componentWillUnmount = function () {
            this.releaseHandler();
        };
        Provider.prototype.render = function () {
            if (StateContext === null) {
                return null;
            }
            return React.createElement(StateContext.Provider, { value: this.state }, this.props.children);
        };
        return Provider;
    }(React.PureComponent));
    // Consumer
    var Consumer = /** @class */ (function (_super) {
        __extends(Consumer, _super);
        function Consumer() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        Consumer.prototype.render = function () {
            var _this = this;
            if (StateContext === null) {
                throw new Error("You must wrap your <Consumer> components with a <Provider> component.\n" +
                    "You can get <Provider> component via `createContext()` API.");
            }
            return (React.createElement(StateContext.Consumer, null, function (value) {
                return _this.props.children(value);
            }));
        };
        return Consumer;
    }(React.PureComponent));
    return {
        Provider: Provider,
        Consumer: Consumer,
    };
}
exports.createReactContext = createReactContext;
